import get from '@/helpers/getLocale';

export default {
  closingAnnouncement: get(
    'Menjadi sebuah kebahagiaan bagi kami apabila Bapak/Ibu/Saudara/i tetap berkenan memberikan doa restu secara online untuk pelaksanaan hari pernikahan kami. Terima kasih atas segala ucapan, doa, dan perhatian yang diberikan.',
    'It will be a joy for us if you are still willing to give your blessing from afar. Thank you for all the words, prayers, and attention given.',
  ),
  closingInvitation: get(
    'Menjadi sebuah kebahagiaan bagi kami apabila Bapak/Ibu/Saudara/i berkenan hadir dalam hari bahagia kami. Terima kasih atas segala ucapan, doa, dan perhatian yang diberikan.',
    'It will be a joy for us if you are able to attend our wedding day. Thank you for all the kind words, prayers, and attention given.',
  ),
};
