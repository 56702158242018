import get from '@/helpers/getLocale';

export default {
  title: get('Doa & Ucapan', `Pray & Wishes`),
  desc: get(
    `Silakan kirimkan doa dan ucapan untuk kami:`,
    `Please leave your sincere prayers and wishes to us and our family:`,
  ),
  name: get('Nama', 'Name'),
  relationship: get('Relasi', 'The Relationship'),
  wish: get('Doa & Ucapan', 'Pray & Wishes'),
  send: get('Kirim', 'Submit'),
  success: get('Sukses!', 'Success!'),
  successMessage: get(`Terima kasih atas doa dan ucapannya!`, 'Thank you for the pray and wishes!'),
  failedMessage: get(
    `Terjadi kesalahan. Silahkan refresh browser Anda atau coba lagi nanti.`,
    `Something wrong happened. Please try again or reload the browser`,
  ),
  requiredField: get('Wajib diisi.', 'This field is required.'),
};
