import React from 'react';
import { string } from 'prop-types';
import { Box, Text, Heading, Center } from '@chakra-ui/react';

import WithAnimation from '@/components/Common/WithAnimation';
import InstagramButton from '@components/Common/InstagramButton';
import { HEADING_STYLES } from '@constants/colors';

function DetailCard({ fullName, instagramId, description }) {
  return (
    <Box color="mainColorText" textAlign="center" paddingBottom={'32px'}>
      <Box padding="0 14px">
        <Center>
          <WithAnimation>
            <Heading
              {...HEADING_STYLES}
              marginTop="16px"
              fontSize="28px"
              textAlign="center"
              paddingLeft="0"
              textTransform={'uppercase'}
              dangerouslySetInnerHTML={{ __html: fullName }}
            />
          </WithAnimation>
        </Center>

        {instagramId && (
          <WithAnimation>
            <Box margin={'6px 0'}>
              <InstagramButton
                id={instagramId}
                text={instagramId}
                color="mainColorTextLight"
                fontSize="18px"
                onlyIcon={false}
              />
            </Box>
          </WithAnimation>
        )}
        <WithAnimation>
          <Text
            dangerouslySetInnerHTML={{ __html: description.replace(' & ', '<br/> & ') }}
            fontSize={'18px'}
            fontStyle={'italic'}
          />
        </WithAnimation>
      </Box>
    </Box>
  );
}

DetailCard.propTypes = {
  fullName: string.isRequired,

  instagramId: string.isRequired,
  description: string.isRequired,
};

export default React.memo(DetailCard);
