const img = '';

export default [
  {
    img: img,
    title: '9 APRIL 2018',
    description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam sollicitudin malesuada sapien, sit amet sodales ex sagittis quis. Suspendisse facilisis mi volutpat urna pulvinar, quis aliquet dui lobortis. Sed egestas consequat risus, eu mollis est tincidunt accumsan.`,
  },
  {
    img: img,
    title: '25 MAY 2018',
    description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam sollicitudin malesuada sapien, sit amet sodales ex sagittis quis. Suspendisse facilisis mi volutpat urna pulvinar, quis aliquet dui lobortis. Sed egestas consequat risus, eu mollis est tincidunt accumsan.`,
  },
  {
    img: img,
    title: '20 AUGUST 2018',
    description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam sollicitudin malesuada sapien, sit amet sodales ex sagittis quis. Suspendisse facilisis mi volutpat urna pulvinar, quis aliquet dui lobortis. Sed egestas consequat risus, eu mollis est tincidunt accumsan.`,
  },
  {
    img: img,
    title: '3 FEBRUARY 2023',
    description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam sollicitudin malesuada sapien, sit amet sodales ex sagittis quis. Suspendisse facilisis mi volutpat urna pulvinar, quis aliquet dui lobortis. Sed egestas consequat risus, eu mollis est tincidunt accumsan.`,
  },
];
