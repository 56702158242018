import React from 'react';
import { Box } from '@chakra-ui/react';
import './styles.css';
import {
  BG_COVER_SLIDESHOW_1,
  BG_COVER_SLIDESHOW_2,
  BG_COVER_SLIDESHOW_3,
  BG_COVER_SLIDESHOW_4,
  BG_COVER_SLIDESHOW_5,
} from '@/constants/assets';

function ImagesFade() {
  return (
    // <Box width="100%" zIndex="1" transform="translateY(200)">
    <div className="slide">
      <Box
        backgroundImage={`linear-gradient(rgb(50 48 48/ 50%), rgb(50 48 48 / 50%)),url("${BG_COVER_SLIDESHOW_1}")`}
      />
      <Box
        backgroundImage={`linear-gradient(rgb(50 48 48/ 50%), rgb(50 48 48 / 50%)), url("${BG_COVER_SLIDESHOW_2}")`}
      />
      <Box
        backgroundImage={`linear-gradient(rgb(50 48 48/ 50%), rgb(50 48 48 / 50%)),url("${BG_COVER_SLIDESHOW_3}")`}
      />
      <Box
        backgroundImage={`linear-gradient(rgb(50 48 48/ 50%), rgb(50 48 48 / 50%)),url("${BG_COVER_SLIDESHOW_4}")`}
      />
      <Box
        backgroundImage={`linear-gradient(rgb(50 48 48/ 50%), rgb(50 48 48 / 50%)),url("${BG_COVER_SLIDESHOW_5}")`}
      />
    </div>
    // </Box>
  );
}

export default ImagesFade;
