import get from '@/helpers/getLocale';

export default {
  title: get('Tanda Kasih', 'Wedding Gift'),
  button: get('Kirim Hadiah', 'Send Gift'),
  desc: get(
    `Bagi yang ingin memberikan tanda kasih,</br> dapat mengirimkan melalui fitur di bawah ini:`,
    `For beloved ones who may want to show </br> your sincere love by sending gift, please </br> kindly tap the button down below:`,
  ),
  confirm: get('Konfirmasi', 'Confirmation'),
  sendGift: get('Hadiah', 'Gift'),
  modalDesc: get(
    `Bagi Bapak/Ibu/Saudara/i yang ingin memberikan tanda kasih kepada mempelai dan keluarga
    dapat melakukan transfer melalui rekening berikut:`,
    `For beloved one who may want to showing your sincere love with sending gift, please kindly tap the button down below:`,
  ),
  modalCopy: get('Salin', 'Copy'),
  modalDescAddress: get(
    `Bagi Bapak/Ibu/Saudara/i yang ingin memberikan tanda kasih kepada mempelai dan keluarga
  dapat mengirimkan hadiah di alamat berikut:`,
    `For beloved one who may want to showing your sincere love with sending gift, please kindly send to this address below:`,
  ),
  success: get('Sukses!', 'Success!'),
  successCopyRekening: get(
    'Nomor rekening berhasil disalin.',
    'Account number copied successfully.',
  ),
  successCopyAddress: get('Alamat berhasil disalin.', 'Address copied successfully.'),
  failedCopied: get(
    'Terjadi kesalahan. Mohon muat ulang browser Anda atau coba lagi nanti.',
    'Something wrong happened. Please try again later or refresh your browser!',
  ),
};
