import React from 'react';
import { Box, Button, Link, Heading } from '@chakra-ui/react';

import { YOUTUBE_LINK } from '@/constants';

import txt from './locales';
import useLang from '@hooks/useLang';
import {
  HEADING_STYLES,
  DEFAULT_BUTTON_PROPS,
  TEXT_DECORATION,
  TEXT_PRIMARY,
} from '@constants/colors';
import { ID_YOUTUBE_SECTION } from '@constants/identifier';
import { BG_LIVESTREAM } from '@constants/assets';

function YoutubeLiveSection() {
  const defaultLang = useLang();
  const LANG = defaultLang;

  return (
    <Box name={ID_YOUTUBE_SECTION}>
      <Box
        width="100%"
        height="280px"
        bgImage={`url(${BG_LIVESTREAM})`}
        bgSize="cover"
        bgPosition="center"
        display={'flex'}
        flexDir={'column'}
        justifyContent={'center'}
        alignItems={'center'}
      >
        <Heading {...HEADING_STYLES} fontSize={'28px'} color="secondaryColorText">
          {txt.title[LANG]}
        </Heading>
        <Link href={YOUTUBE_LINK} target="_blank">
          <Button
            {...DEFAULT_BUTTON_PROPS}
            fontSize={'18px'}
            padding="16px 30px"
            _hover={{
              backgroundColor: TEXT_DECORATION,
              borderColor: TEXT_PRIMARY,
              transform: 'none',
            }}
          >
            {txt.open[LANG]}
          </Button>
        </Link>
      </Box>
    </Box>
  );
}

export default React.memo(YoutubeLiveSection);
