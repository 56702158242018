import React from 'react';
import { Box, Text } from '@chakra-ui/react';

import CountDown from '@components/Common/CountDown';
import WithAnimation from '@/components/Common/WithAnimation';

import useLang from '@hooks/useLang';
import useInvitation from '@hooks/useInvitation';

import txtWording from './locales';

function CountingDown() {
  const lang = useLang();
  const invitation = useInvitation();
  return (
    <Box padding={'3rem'} bgColor="bgPrimary">
      <WithAnimation>
        <Text textAlign="center" fontSize="lg" color="mainColorText" marginBottom="1rem">
          {invitation ? txtWording.closingInvitation[lang] : txtWording.closingAnnouncement[lang]}
        </Text>
      </WithAnimation>
      <CountDown lang={lang} />
    </Box>
  );
}

export default CountingDown;
