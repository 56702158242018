import React from 'react';
import { func } from 'prop-types';

import { Box, Center, Heading, Text, Button } from '@chakra-ui/react';
import { FaChevronDown } from 'react-icons/fa';

import {
  BG_PRIMARY,
  HASHTAG_STYLE,
  HEADING_ALTERNATIVE_STYLES,
  HEADING_STYLES,
} from '@/constants/colors';
import { BOY_NAME_SHORT, GIRL_NAME_SHORT, IS_BOY_FIRST, BRIDE_HASHTAG } from '@constants/index';
import useInvitation from '@hooks/useInvitation';
import useLang from '@hooks/useLang';
import txtWording from './locales';
import ImagesFade from '@components/Common/ImagesFade';

function CoverInner({ onScrollToWelcome }) {
  const isInvitation = useInvitation();
  const lang = useLang();

  return (
    <Box
      minHeight="100vh"
      backgroundColor={BG_PRIMARY}
      color="secondaryColorText"
      position="relative"
    >
      <ImagesFade />
      {/* Text and Content */}
      <Box height="100vh" width="100%" padding="2rem" color="white" zIndex="2" position="relative">
        <Heading marginTop="60px" marginBottom="8px" {...HEADING_ALTERNATIVE_STYLES}>
          {isInvitation ? 'WEDDING INVITATION' : 'WEDDING ANNOUNCEMENT'}
        </Heading>

        <Center height="calc(90% - 130px)">
          <Box textAlign="center">
            <Heading {...HEADING_STYLES} textTransform="uppercase">
              {IS_BOY_FIRST ? BOY_NAME_SHORT : GIRL_NAME_SHORT} &{' '}
              {IS_BOY_FIRST ? GIRL_NAME_SHORT : BOY_NAME_SHORT}
            </Heading>
            <Heading {...HASHTAG_STYLE}>{BRIDE_HASHTAG}</Heading>
          </Box>
        </Center>

        <Button
          onClick={onScrollToWelcome}
          background={'transparent'}
          _hover={{ background: 'transparent' }}
          _active={{ background: 'transparent' }}
          marginLeft={'auto'}
          padding={'0px'}
          display="flex"
          justifyContent="flex-end"
          marginTop={'4rem'}
          alignItems="center"
        >
          <Text
            fontFamily="headingAlternative"
            textTransform={'uppercase'}
            fontWeight="bold"
            marginRight="7px"
            fontSize="sm"
          >
            {txtWording.scroll[lang]}
          </Text>
          <FaChevronDown size={17} />
        </Button>
      </Box>
    </Box>
  );
}

CoverInner.propTypes = {
  onScrollToWelcome: func.isRequired,
};
export default CoverInner;
