import React, { useState } from 'react';
import { string } from 'prop-types';
import { Box, Heading, Text } from '@chakra-ui/react';
import WithAnimation from '@components/Common/WithAnimation';

import { useGuest } from '@/context/guest';

import useInvitation from '@hooks/useInvitation';
import { THE_BRIDE } from '@constants/index';

import { photos } from './api/photos-compassion';

import txtWording from './locales';
import { MUSLIM_INVITATION } from '@constants/feature-flags';
import { HEADING_ALTERNATIVE_STYLES } from '@constants/colors';

import CarouselGallery from '../../Common/CarouselGallery';

const WelcomeV4 = React.forwardRef(({ lang, ...rest }, ref) => {
  const { guest } = useGuest();
  const { name } = guest;
  const isInvitation = useInvitation();

  const [activeSlide, setActiveSlide] = useState(0);

  return (
    <Box
      ref={ref}
      padding="50px 0 100px 0"
      textAlign={'center'}
      bgColor="bgPrimary"
      color="mainColorText"
      {...rest}
    >
      <WithAnimation>
        <Box {...HEADING_ALTERNATIVE_STYLES}>
          <Text fontSize={'12px'}>{txtWording.dear[lang]}</Text>
          <Text fontSize={'12px'}>{name ? `${name}` : txtWording.guest[lang]}</Text>
        </Box>
      </WithAnimation>

      <WithAnimation>
        <Heading padding={'24px 0'} fontWeight={'medium'} fontSize="32px">
          <>
            Welcome to
            <br />
            {THE_BRIDE}’s
            <br />
            Wedding Website
          </>
        </Heading>
      </WithAnimation>

      <WithAnimation>
        {MUSLIM_INVITATION ? (
          <Text
            padding="0px 10% 24px 10%"
            fontSize={'18px'}
            fontStyle={'italic'}
            marginBottom={'18px'}
            dangerouslySetInnerHTML={{
              __html: isInvitation
                ? txtWording.invitationMuslim[lang]
                : txtWording.announcementMuslim[lang],
            }}
          />
        ) : (
          <Text
            padding="0px 10% 24px 10%"
            fontSize={'18px'}
            fontStyle={'italic'}
            marginBottom={'18px'}
            dangerouslySetInnerHTML={{
              __html: isInvitation
                ? txtWording.welcomingInvitationGeneral[lang]
                : txtWording.welcomingAnnouncementGeneral[lang],
            }}
          />
        )}
      </WithAnimation>

      <CarouselGallery images={photos} activeSlide={activeSlide} setActiveSlide={setActiveSlide} />
    </Box>
  );
});

WelcomeV4.propTypes = {
  lang: string.isRequired,
};

WelcomeV4.displayName = 'WelcomeV4';

export default WelcomeV4;
