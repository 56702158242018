import get from '@invitato/helpers/dist/getLocale';

export const txtTitle = get(
  `Kehadiran Bapak/Ibu/Saudara/i akan menjadi kehormatan besar bagi kami dan keluarga. Mohon konfirmasi kehadiran Anda di bawah:`,
  `We'd love to hear from you! Please fill out the confirmation below:`,
);

export const txtForm = {
  name: get('Nama', 'Name'),
  nameHelper: get(
    '*) RSVP bersifat personal sehingga hanya bisa mengkonfirmasikan 1 nama tamu undangan saja',
    '*) Guest can only confirm 1 name due to personalized RSVP',
  ),
  hp: get('Nomor HP', 'Phone'),
  address: get('Alamat', 'Address'),
  email: get('Email', 'Email'),
  willYoutAttend: get('Apakah anda akan hadir?', 'Will you attend the wedding?'),
  willAttend: get('Akan Hadir', 'Gladly Attend'),
  noAttend: get('Tidak Bisa Hadir', `Unable to Attend`),
  fillOutForm: get(
    'Isikan data Bapak/Ibu/Saudara/i untuk memberikan konfirmasi kehadiran: ',
    'Fill out the form to complete your reservation:',
  ),
  willYouBringPartner: get('Berapa jumlah tamu yang akan hadir?', `How many guests will attend?`),
  withPartner: get('Ya, saya akan membawa 1 partner', `Yes, I will bring 1 partner here`),
  noPartner: get('Tidak, saya akan hadir sendiri', `No, I will be by myself`),
  required: get('Wajib diisi', 'This field required'),
  success: get('Sukses!', 'Success!'),
  msgSuccess: get(
    'Formulir reservasi kamu berhasil dikirim. Terima Kasih!',
    'Your response already submitted. Thank you!',
  ),
  msgError: get(
    'Ada kesalahan ketika mengirimkan reservasi kamu. Silahkan coba beberapa saat lagi.',
    'Something wrong happened. Please try again later or refresh your browser!',
  ),
  previousBtn: get('Sebelumnya', 'Previous'),
  nextBtn: get('Selanjutnya', 'Next'),
  submitBtn: get('Kirim', 'Submit'),
  responseSubmitted: get(
    'Konfirmasi kehadiran Anda sudah kami terima. Terima kasih!',
    'Your response already submitted. Thank you!',
  ),
  submitting: get('Mengirim…', 'Submitting…'),
  invalidEmail: get('Email tidak valid', 'Invalid email address'),
  invalidPhone: get('Nomor HP tidak valid', 'Invalid phone number'),
  changeInput: get('Ganti Input RSVP', 'Change RSVP'),
  attendInfo: get(`Acara apa yang akan Anda hadiri?`, `Which event will you attend?`),
};
