import get from '@invitato/helpers/dist/getLocale';

export default {
  ourSincere: get('KAMI YANG BERBAHAGIA,', 'OUR SINCERE,'),
  ayatName: get('Matius 19:6', '1 Corinthians 13:4-8'),
  ayatNameMuslim: get('Q.S. Ar-Rum: 21', 'Q.S. Ar-Rum: 21'),
  ayat: get(
    `Demikianlah mereka bukan lagi dua, melainkan satu. Karena itu, apa yang telah dipersatukan Allah, tidak boleh diceraikan manusia.`,
    `Love is patient, love is kind, and is not jealous; love does not brag and is not arrogant, does not act unbecomingly; it does not seek its own, is not provoked, does not take into account a wrong suffered, does not rejoice in unrighteousness, but rejoices with the truth; bears all things, believes all things, hopes all things, endures all things. Love never fails; but if there are gifts of prophecy, they will be done away; if there are tongues, they will cease; if there is knowledge, it will be done away.`,
  ),
  ayatMuslim: get(
    `Dan di antara tanda-tanda (kebesaran)-Nya ialah Dia menciptakan pasangan-pasangan untukmu dari jenismu sendiri, agar kamu cenderung dan merasa tenteram kepadanya, dan Dia menjadikan diantaramu rasa kasih dan sayang. Sungguh, pada yang demikian itu benar-benar terdapat tanda-tanda (kebesaran Allah) bagi kaum yang berpikir.`,
    `And of His signs is that He created for you from yourselves mates that you may find tranquility in them, and He placed between you affection and mercy. Indeed in that are signs for a people who give thought.`,
  ),
  thanks: get(
    'TERIMAKASIH UNTUK SEMUA KATA, <br />DOA, DAN PERHATIAN YANG DIBERIKAN.',
    'THANK YOU FOR ALL THE WORDS, <br />PRAYERS, AND ATTENTION GIVEN',
  ),
  closingAnnouncement: get(
    'Menjadi sebuah kebahagiaan bagi kami apabila Bapak/Ibu/Saudara/i tetap berkenan memberikan doa restu untuk pelaksanaan hari pernikahan kami. Terima kasih atas segala ucapan, doa, dan perhatian yang diberikan.',
    'It will be a joy for us if you are still willing to give your blessing from afar. Thank you for all the words, prayers, and attention given.',
  ),
  closingInvitation: get(
    'Menjadi sebuah kebahagiaan bagi kami apabila Bapak/Ibu/Saudara/i berkenan hadir dalam hari bahagia kami. Terima kasih atas segala ucapan, doa, dan perhatian yang diberikan.',
    'It will be a joy for us if you are able to attend our wedding day. Thank you for all the kind words, prayers, and attention given.',
  ),
};
