import React from 'react';
import { string, bool } from 'prop-types';
import { Link, LinkBox } from '@chakra-ui/react';
import { FaInstagram } from 'react-icons/fa';

function InstagramButton({ onlyIcon, id, text, ...rest }) {
  const handleRedirect = () => {
    if (!id) return '';

    window.open(`https://instagram.com/${id}`, '_blank');
  };

  if (onlyIcon) {
    return (
      <LinkBox as="button" onClick={handleRedirect} {...rest}>
        <FaInstagram color="currentcolor" />
      </LinkBox>
    );
  }

  return (
    <Link
      href={`https://instagram.com/${id}`}
      isExternal
      textDecoration="none"
      _hover={{ textDecoration: 'underline' }}
      {...rest}
      fontFamily={'heading'}
      fontWeight={'light'}
      fontSize={'xl'}
    >
      {'@' + text}
    </Link>
  );
}

InstagramButton.propTypes = {
  id: string,
  onlyIcon: bool,
  text: string,
};

InstagramButton.defaultProps = {
  id: '',
  onlyIcon: false,
  text: 'Instagram',
};

export default InstagramButton;
