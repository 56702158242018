import React, { useState, useCallback, useRef } from 'react';
import { Box } from '@chakra-ui/react';
import { func, string, bool, object } from 'prop-types';

import CoverSection from '@components/Section/Cover';
import CoverInner from '@components/Section/CoverInner';
import Welcome from '@components/Section/Welcome';
import CoupleInfoSection from '@components/Section/CoupleInfo';
import WeddingSection from '@components/Section/Wedding';
import RSVPSection from '@components/Section/RSVP';
import YoutubeLive from '@components/Section/YoutubeLive';
import GiftCard from '@components/Section/GiftCard';
import WishesSection from '@components/Section/Wishes';
import CountingDown from '@components/Section/CountingDown';
import Footer from '@components/Section/Footer';
import LoveStory from '@components/Section/LoveStory';
import InstagramFilter from '@components/Section/InstagramFilter';
import CovidProtocol from '@components/Common/CovidModal/Lazy';
import WithAnimation from '@components/Common/WithAnimation';

import Layout from '@components/Layout';
import FloatingMusic from '@components/Common/FloatingMusic/Lazy';
import Navigation from '@components/Common/Navigation';
import DarkmodeNotification from '@components/Common/DarkmodeNotification';

import useInvitation from '@/hooks/useInvitation';
import useLang from '@/hooks/useLang';

import { GuestProvider } from '@/context/guest';

import * as ID from '../constants/identifier';
import * as F from '../constants/feature-flags';

const ELEMENT_CONTENT_ID = 'invitato-detail-content';

export default function Home() {
  const [showDetailContent, setShowDetailContent] = useState(false);
  const lang = useLang();

  /**
   * function to show detail invitation
   * @returns {void}
   */
  const handleClickDetail = useCallback(() => {
    setShowDetailContent(true);
  }, []);

  /**
   * function to render invitation content
   * @returns {JSX.Element}
   */

  const welcomeRef = useRef(null);
  const scrollToWelcome = () => {
    welcomeRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <GuestProvider>
      <Layout>
        <HomePageContent
          showDetailContent={showDetailContent}
          lang={lang}
          handleClickDetail={handleClickDetail}
          welcomeRef={welcomeRef}
          scrollToWelcome={scrollToWelcome}
        />
      </Layout>
    </GuestProvider>
  );
}

function HomePageContent({
  showDetailContent,
  lang,
  handleClickDetail,
  welcomeRef,
  scrollToWelcome,
}) {
  const invitation = useInvitation();

  return (
    <>
      {F.ENABLE_MUSIC_BACKSOUND && <FloatingMusic shouldShowMusicIcon={showDetailContent} />}
      {F.ENABLE_DARKMODE_NOTIFICATION && <DarkmodeNotification lang={lang} />}
      {showDetailContent && <Navigation />}
      {F.ENABLE_COVID_PROTOCOL && showDetailContent && invitation && (
        <CovidProtocol visible={showDetailContent} lang={lang} />
      )}
      <WithAnimation top collapse when={!showDetailContent}>
        <CoverSection loaded={showDetailContent} onSeeDetail={handleClickDetail} />
      </WithAnimation>

      {showDetailContent ? (
        <Box id={ELEMENT_CONTENT_ID}>
          <CoverInner onScrollToWelcome={scrollToWelcome} />
          <Welcome ref={welcomeRef} lang={lang} />
          <CoupleInfoSection name={ID.ID_COUPLE_SECTION} />
          <WeddingSection name={ID.ID_DETAIL_SECTION} />
          {invitation && F.ENABLE_RSVP && <RSVPSection lang={lang} name={ID.ID_RSVP_SECTION} />}
          {F.ENABLE_GIFT_CARD && <GiftCard lang={lang} name={ID.ID_GIFT_SECTION} />}
          {F.ENABLE_INSTAGRAM_FILTER && invitation && <InstagramFilter />}
          {F.ENABLE_LIVE_STREAMING && <YoutubeLive lang={lang} name={ID.ID_YOUTUBE_SECTION} />}
          <LoveStory lang={lang} />
          <WishesSection lang={lang} />
          <CountingDown />
          <Footer />
        </Box>
      ) : null}
    </>
  );
}

HomePageContent.propTypes = {
  showDetailContent: bool,
  lang: string,
  handleClickDetail: func,
  welcomeRef: object,
  scrollToWelcome: func,
};
