import React, { useState } from 'react';
import Carousel from 'react-simply-carousel';
import { HiOutlineArrowNarrowLeft, HiOutlineArrowNarrowRight } from 'react-icons/hi';

import { Box, useDisclosure } from '@chakra-ui/react';
import { array, func, number } from 'prop-types';
import ImagesViever from '../ImagesViewer';

function CarouselGallery({ images, activeSlide, setActiveSlide }) {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [firstIndex, setFirstIndex] = useState(0);

  const handleShowImage = (index) => {
    onOpen();
    setFirstIndex(index);
  };

  return (
    <Box position={'relative'}>
      <Carousel
        containerProps={{
          style: {
            width: '100%',
            justifyContent: 'space-between',
            userSelect: 'none',
          },
        }}
        preventScrollOnSwipe
        swipeTreshold={60}
        activeSlideIndex={activeSlide}
        activeSlideProps={{
          style: {
            transform: 'scale(1.05)',
            WebkitTransition: 'transform 1.5s linear',
            transition: 'transform 1.5s linear',
          },
        }}
        onRequestChange={setActiveSlide}
        forwardBtnProps={{
          style: {
            position: 'absolute',
            right: '2.5rem',
            bottom: '-50px',
            alignSelf: 'center',
            background: '#725645',
            color: '#F2F2F2',
            border: '1px solid black',
            cursor: 'pointer',
            height: 30,
            lineHeight: 1,
            textAlign: 'center',
            width: 70,
            padding: '5px 10px',
          },
          children: (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                height: '100%',
                fontSize: '24px',
              }}
            >
              <HiOutlineArrowNarrowRight />
            </div>
          ),
        }}
        backwardBtnProps={{
          style: {
            position: 'absolute',
            right: '120px',
            bottom: '-50px',
            alignSelf: 'center',
            background: '#725645',
            color: '#F2F2F2',
            border: '1px solid black',
            cursor: 'pointer',
            height: 30,
            lineHeight: 1,
            textAlign: 'center',
            width: 70,
            padding: '5px 10px',
          },
          children: (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                height: '100%',
                fontSize: '24px',
              }}
            >
              <HiOutlineArrowNarrowLeft />
            </div>
          ),
        }}
        itemsToShow={2}
        // autoplay={true}
        // autoplayDelay={1000}
        speed={1000}
        easing="linear"
        centerMode
      >
        {images.map((item, index) => {
          return (
            <Box
              style={{
                transform: 'scale(1.0)',
                WebkitTransition: 'transform 1.2s linear',
                transition: 'transform 1.2s linear',
              }}
              height={390}
              width={280}
              key={index}
              bgImage={item}
              onClick={() => handleShowImage(index)}
              cursor={'pointer'}
              bgPos={'center'}
              bgSize={'cover'}
              border={'10px solid #EDEDED'}
            />
          );
        })}
      </Carousel>
      {isOpen && <ImagesViever images={images} firstIndex={firstIndex} onClose={onClose} />}
    </Box>
  );
}

CarouselGallery.propTypes = {
  images: array.isRequired,
  activeSlide: number.isRequired,
  setActiveSlide: func.isRequired,
};

export default CarouselGallery;
