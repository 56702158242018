import get from '@invitato/helpers/dist/getLocale';

export default {
  title: get('WAKTU & TEMPAT', 'PLACE & TIME'),
  ayatName: get('Q.S. Ar-Rum : 21', '1 Corinthians 13:4-8'),
  ayatNameMuslim: get('Q.S. Ar-Rum : 21', 'Q.S. Ar-Rum: 21'),
  ayat: get(
    `Dan di antara tanda-tanda (kebesaran)-Nya ialah Dia menciptakan pasangan-pasangan untukmu dari jenismu sendiri, agar kamu cenderung dan merasa tenteram kepadanya, dan Dia menjadikan diantaramu rasa kasih dan sayang. Sungguh, pada yang demikian itu benar-benar terdapat tanda-tanda (kebesaran Allah) bagi kaum yang berpikir.`,
    `Love is patient, love is kind, and is not jealous; love does not brag and is not arrogant, does not act unbecomingly; it does not seek its own, is not provoked, does not take into account a wrong suffered, does not rejoice in unrighteousness, but rejoices with the truth; bears all things, believes all things, hopes all things, endures all things. Love never fails; but if there are gifts of prophecy, they will be done away; if there are tongues, they will cease; if there is knowledge, it will be done away.`,
  ),
  ayatMuslim: get(
    `Dan di antara tanda-tanda (kebesaran)-Nya ialah Dia menciptakan pasangan-pasangan untukmu dari jenismu sendiri, agar kamu cenderung dan merasa tenteram kepadanya, dan Dia menjadikan diantaramu rasa kasih dan sayang. Sungguh, pada yang demikian itu benar-benar terdapat tanda-tanda (kebesaran Allah) bagi kaum yang berpikir.`,
    `And of His signs is that He created for you from yourselves mates that you may find tranquility in them, and He placed between you affection and mercy. Indeed in that are signs for a people who give thought.`,
  ),
  location: get('Lihat Lokasi', 'See Location'),
  akadMuslim: get('Akad Nikah', 'The Religious Ceremony'),
  akadGeneral: get('Pemberkatan', 'Holy Matrimony'),
  reception: get('Resepsi', 'Wedding Reception'),
  calendar: get('Ingatkan Acara', 'Add to Calendar'),
  date: get('Tanggal', 'Date'),
  time: get('Waktu', 'Time'),
  place: get('Tempat', 'Place'),
};
