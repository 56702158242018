import React, { useState, useMemo, useCallback } from 'react';
import { string } from 'prop-types';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  Heading,
  useToast,
  Text,
  RadioGroup,
  Radio,
  Spinner,
  Select,
} from '@chakra-ui/react';

import WithAnimation from '@components/Common/WithAnimation';
import ProgressStepper from '@components/Common/ProgressStepper';

import { useGuest } from '@/context/guest';
import useFormFields from '@hooks/useFormFields';
import useRsvp from '@/usecase/use-rsvp';
import useAddNewGuest from '@/usecase/use-add-new-guest';

import { RSVP_STATUS, API_ATTEND_INFO } from '@constants/api';
import { BG_RSVP } from '@constants/assets';
import { HEADING_STYLES } from '@constants/colors';
import {
  ENABLE_GUEST_PERSONALIZATION,
  ENABLE_SHIFT_TIME_RECEPTION,
  ENABLE_QR_INVITATION,
  RSVP_ENABLE_NAME,
  RSVP_ENABLE_PHONE_NUMBER,
  RSVP_ENABLE_ADDRESS,
  RSVP_ENABLE_EMAIL,
  RSVP_ENABLE_ATTEND_INFO,
} from '@constants/feature-flags';

import {
  TYPE,
  ERROR_TYPE,
  INPUT_COMMON_PROPS,
  SELECT_COMMON_PROPS,
  HOVER_PROPS,
  RSVP_BUTTON_PROPS,
  RSVP_STATE,
  getStepNumber,
  BTN_ALTERNATE,
} from './types';

import { MemoPhoneNumberList } from './_Utils';
import { txtForm, txtTitle } from './locales';

/**
 * function to render RSVP component
 * @returns {JSX.Element}
 * @author idindrakusuma
 */
function RSVPSection({ lang, ...rest }) {
  const toast = useToast();
  const { guest, onUpdateGuest, isRegistered } = useGuest();

  const { onAddNewGuest } = useAddNewGuest();
  const { onUpdateRsvp } = useRsvp();

  const filteredEventInfo = useMemo(() => {
    if (!RSVP_ENABLE_ATTEND_INFO || !ENABLE_SHIFT_TIME_RECEPTION) return [];

    return API_ATTEND_INFO.filter((item) => item.shift.includes(guest.shift));
  }, [guest.shift]);

  const [rsvpState, setRsvpState] = useState(RSVP_STATE.attend);

  const currentStep = getStepNumber(rsvpState);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const [errorType, setErrorType] = useState(ERROR_TYPE);
  const [isLoading, setIsLoading] = useState(false);
  const { formFields, onResetForm, createChangeHandler, onChangeHandlerWithVal } = useFormFields({
    name: isRegistered ? guest.name : '',
    phone_number: guest.phone_number || '',
    guest_quota: guest.guest_quota,
    total_guest: guest.guest_confirm || guest.guest_quota || 1,
    address: guest.address === '-' ? '' : guest.address || '',
    email: guest.email === '-' ? '' : guest.email || '',
    is_attended: guest.status === 'NOT_ATTEND' ? TYPE.NO : TYPE.YES,
    attend_info: RSVP_ENABLE_ATTEND_INFO ? guest.attend_info || filteredEventInfo[0]?.value : '',
  });

  const [selectedCountryCode, setSelectedCountryCode] = useState(guest.country_code || '62');

  const handleCountryCodeChange = useCallback((code) => {
    setSelectedCountryCode(code);
  }, []);

  const onNextInfo = async () => {
    const { name, address, phone_number, is_attended, email } = formFields;

    setErrorType(ERROR_TYPE);
    // define current error state
    let currentErrorType = ERROR_TYPE;

    // Make sure user already input the value
    /**
     * ERROR CHECKING
     */
    if (RSVP_ENABLE_NAME && !name) {
      currentErrorType = { ...currentErrorType, name: txtForm.required[lang] };
    }

    if (RSVP_ENABLE_PHONE_NUMBER) {
      if (!phone_number) {
        currentErrorType = { ...currentErrorType, phone: txtForm.required[lang] };
      } else if (phone_number.length > 13 || phone_number.length < 5) {
        currentErrorType = { ...currentErrorType, phone: txtForm.invalidPhone[lang] };
      }
    }

    if (RSVP_ENABLE_ADDRESS && !address) {
      currentErrorType = { ...currentErrorType, address: txtForm.required[lang] };
    }

    if (RSVP_ENABLE_EMAIL) {
      if (!email) {
        currentErrorType = { ...currentErrorType, email: txtForm.required[lang] };
      } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)) {
        currentErrorType = { ...currentErrorType, email: txtForm.invalidEmail[lang] };
      }
    }

    if (
      currentErrorType.address ||
      currentErrorType.email ||
      currentErrorType.name ||
      currentErrorType.phone
    ) {
      setErrorType(currentErrorType);
      return;
    }

    if (is_attended === TYPE.NO) {
      onSubmitForm(); // gather the data and then set the state to "submitted"
    } else {
      setRsvpState(RSVP_STATE.confirmation);
    }
  };

  const callToasterError = (desc, title = 'Oops!') => {
    toast({
      title,
      description: desc,
      status: 'error',
      isClosable: true,
    });
  };

  const callToasterSuccess = (desc, title = txtForm.success[lang]) => {
    toast({
      title,
      description: desc,
      status: 'success',
      duration: 5000,
      isClosable: true,
    });
  };

  /**
   * function to submit to BE with check the form value first
   */
  const onSubmitForm = async () => {
    const {
      name,
      address,
      phone_number,
      total_guest,
      is_attended,
      email,
      attend_info,
    } = formFields;
    setIsLoading(true);

    const guestPayload = {
      name,
      address: address || '-',
      countryCode: selectedCountryCode,
      phoneNumber: phone_number || '-',
      email: email || '-',
      shift: guest.shift || '-',
      attendInfo: is_attended === TYPE.YES ? attend_info : '',
      system_info: 'WARNING_Terdapat Perubahan dari User_',
    };

    // Usecase: Group or General Invitation
    // We will `insert` instead of `update` if user doens't have code yet. But,
    // we will throw error if QR Invitation
    if (!guest.code) {
      if (ENABLE_QR_INVITATION) {
        setIsLoading(false);
        callToasterError(txtForm.msgError[lang]);
        return;
      }
      await onAddNewGuest({
        ...guestPayload,
        desc: guest.name || '-',
        guest_quota: guest.guest_quota,
        guest_confirm: total_guest && is_attended === TYPE.YES ? total_guest : 0,

        status: is_attended === TYPE.YES ? RSVP_STATUS.attend : RSVP_STATUS.notAttend,
        onSuccess: (guestCode) => {
          setRsvpState(RSVP_STATE.submitted);
          setIsSubmitted(true);

          setIsLoading(false);
          callToasterSuccess(txtForm.msgSuccess[lang]);
          onUpdateGuest(guestCode, name);
        },
        onFailed: () => {
          setIsLoading(false);
          callToasterError(txtForm.msgError[lang]);
        },
      });
      return;
    }
    await onUpdateRsvp({
      ...guestPayload,
      desc: guest.desc || '-',
      isAttended: is_attended === TYPE.YES,
      totalGuest: total_guest && is_attended === TYPE.YES ? total_guest : 0,

      onSuccess: () => {
        setRsvpState(RSVP_STATE.submitted);
        setIsSubmitted(true);

        setIsLoading(false);
        // onResetFormWithKey('phone_number');
        callToasterSuccess(txtForm.msgSuccess[lang]);
      },
      onFailed: () => {
        setIsLoading(false);
        callToasterError(txtForm.msgError[lang]);
      },
    });
  };

  const onResumbitRSVP = () => {
    setRsvpState(RSVP_STATE.attend);
    onResetForm();
  };

  return (
    <Box padding="0 0 16px 0" bgColor="bgPrimary" {...rest}>
      <Box
        width="100%"
        height="280px"
        bgImage={`url(${BG_RSVP})`}
        bgSize="cover"
        bgPosition="center"
      />
      <Box bgColor="bgPrimary">
        <Box padding={'2rem'} textAlign={'center'}>
          <WithAnimation>
            <Box width="100%" minHeight={'400px'}>
              {/* Stepper */}
              <Box my={'20px'}>
                <ProgressStepper currentStep={currentStep} totalSteps={3} />
              </Box>

              {isLoading ? (
                <Box marginTop={'35%'}>
                  <Spinner />
                  <Text>{txtForm.submitting[lang]}</Text>
                </Box>
              ) : (
                <>
                  {/* SECTION ATTEND */}
                  {rsvpState === RSVP_STATE.attend && (
                    <Box textAlign="center" color="mainColorText" paddingTop={'12px'}>
                      <Heading
                        {...HEADING_STYLES}
                        paddingLeft="0px"
                        textAlign="center"
                        marginTop="16px"
                        fontSize={'28px'}
                      >
                        RSVP
                      </Heading>

                      <Text fontSize="18px" margin="16px 0 24px 0">
                        {txtTitle[lang]}
                      </Text>

                      <Box display={'flex'} justifyContent={'center'} gap={'15px'}>
                        <Button
                          {...RSVP_BUTTON_PROPS}
                          {...HOVER_PROPS}
                          onClick={() => {
                            onChangeHandlerWithVal('is_attended', TYPE.NO);
                            setRsvpState(RSVP_STATE.info);
                          }}
                        >
                          {txtForm.noAttend[lang]}
                        </Button>
                        <Button
                          {...RSVP_BUTTON_PROPS}
                          {...BTN_ALTERNATE}
                          onClick={() => {
                            onChangeHandlerWithVal('is_attended', TYPE.YES);
                            setRsvpState(RSVP_STATE.info);
                          }}
                        >
                          {txtForm.willAttend[lang]}
                        </Button>
                      </Box>
                    </Box>
                  )}

                  {/* SECTION GUEST INFO */}
                  {rsvpState === RSVP_STATE.info && (
                    <Box textAlign="center" color="mainColorText" paddingTop={'12px'}>
                      <Heading
                        {...HEADING_STYLES}
                        paddingLeft="0px"
                        textAlign="center"
                        marginTop="16px"
                        fontSize={'28px'}
                      >
                        RSVP
                      </Heading>

                      <Text fontSize="18px" margin="16px 0 16px 0">
                        {txtForm.fillOutForm[lang]}
                      </Text>

                      {/* Form Sections - Name */}
                      {RSVP_ENABLE_NAME && (
                        <FormControl
                          margin="12px auto 18px auto"
                          width={'280px'}
                          isInvalid={errorType.name}
                        >
                          <FormLabel fontStyle={'italic'}>{txtForm.nameHelper[lang]}</FormLabel>
                          <Input
                            {...INPUT_COMMON_PROPS}
                            placeholder={txtForm.name[lang]}
                            value={formFields.name}
                            onChange={createChangeHandler('name')}
                            disabled={isRegistered}
                          />
                          <FormErrorMessage marginTop="4px">{errorType.name}</FormErrorMessage>
                        </FormControl>
                      )}

                      {/* Form Sections - Phone Number */}
                      <MemoPhoneNumberList
                        value={formFields.phone_number}
                        createChangeHandler={createChangeHandler}
                        lang={lang}
                        selectedCountryCode={selectedCountryCode}
                        errorPhone={errorType.phone}
                        handleCountryCodeChange={handleCountryCodeChange}
                      />

                      {RSVP_ENABLE_ATTEND_INFO && formFields.is_attended === TYPE.YES && (
                        <FormControl margin="16px auto" width={'280px'}>
                          <FormLabel fontStyle={'italic'}>{txtForm.attendInfo[lang]}</FormLabel>
                          <Select
                            {...SELECT_COMMON_PROPS}
                            value={formFields.attend_info}
                            onChange={createChangeHandler('attend_info')}
                          >
                            {filteredEventInfo.map((item) => {
                              return (
                                <option
                                  key={item.value}
                                  value={item.value}
                                  style={{ color: 'black' }}
                                >
                                  {item.title}
                                </option>
                              );
                            })}
                          </Select>
                        </FormControl>
                      )}

                      {/* Form Sections - Address */}
                      {RSVP_ENABLE_ADDRESS && (
                        <FormControl margin="8px 0" isInvalid={errorType.address}>
                          <Input
                            {...INPUT_COMMON_PROPS}
                            height="40px"
                            placeholder={txtForm.address[lang]}
                            value={formFields.address}
                            onChange={createChangeHandler('address')}
                          />
                          <FormErrorMessage marginTop="4px">{errorType.address}</FormErrorMessage>
                        </FormControl>
                      )}

                      {/* Form Sections - Email */}
                      {RSVP_ENABLE_EMAIL && (
                        <FormControl margin="8px 0" isInvalid={errorType.email}>
                          <Input
                            {...INPUT_COMMON_PROPS}
                            placeholder={txtForm.email[lang]}
                            value={formFields.email}
                            onChange={createChangeHandler('email')}
                          />
                          <FormErrorMessage marginTop="4px">{errorType.email}</FormErrorMessage>
                        </FormControl>
                      )}

                      <Box display={'flex'} justifyContent={'center'} gap={'15px'}>
                        <Button
                          {...RSVP_BUTTON_PROPS}
                          {...HOVER_PROPS}
                          onClick={() => {
                            setRsvpState(RSVP_STATE.attend);
                          }}
                        >
                          {txtForm.previousBtn[lang]}
                        </Button>

                        <Button {...RSVP_BUTTON_PROPS} {...BTN_ALTERNATE} onClick={onNextInfo}>
                          {formFields.is_attended === TYPE.NO
                            ? txtForm.submitBtn[lang]
                            : txtForm.nextBtn[lang]}
                        </Button>
                      </Box>
                    </Box>
                  )}

                  {/* SECTION TOTAL GUEST */}
                  {rsvpState === RSVP_STATE.confirmation && (
                    <Box textAlign="center" color="mainColorText" paddingTop={'16px'}>
                      <Heading
                        {...HEADING_STYLES}
                        paddingLeft="0px"
                        textAlign="center"
                        marginTop="16px"
                        fontSize={'28px'}
                      >
                        RSVP
                      </Heading>

                      <Text fontSize="18px" margin="16px 0">
                        {txtForm.willYouBringPartner[lang]}
                      </Text>

                      <RadioGroup
                        value={formFields.total_guest}
                        onClick={(e) => {
                          if (!e.target.value) {
                            return;
                          } else {
                            // setBringPartner(Number(e.target.value));
                            onChangeHandlerWithVal('total_guest', Number(e.target.value));
                          }
                        }}
                      >
                        {[...Array(ENABLE_GUEST_PERSONALIZATION ? guest.guest_quota : 2)].map(
                          (_, index) => {
                            return (
                              <Box key={index}>
                                <Radio variant={'custom'} value={index + 1}>
                                  <Text fontSize="22px" color="mainColorText">
                                    {index + 1}
                                  </Text>
                                </Radio>
                              </Box>
                            );
                          },
                        )}
                      </RadioGroup>

                      <Box
                        display={'flex'}
                        marginTop={'24px'}
                        justifyContent={'center'}
                        gap={'15px'}
                      >
                        <Button
                          {...RSVP_BUTTON_PROPS}
                          {...BTN_ALTERNATE}
                          onClick={() => {
                            setRsvpState(RSVP_STATE.info);
                          }}
                        >
                          {txtForm.previousBtn[lang]}
                        </Button>
                        <Button
                          {...RSVP_BUTTON_PROPS}
                          {...HOVER_PROPS}
                          isLoading={isLoading}
                          onClick={onSubmitForm}
                        >
                          {txtForm.submitBtn[lang]}
                        </Button>
                      </Box>
                    </Box>
                  )}
                </>
              )}

              {rsvpState === RSVP_STATE.submitted && isSubmitted && (
                <Box textAlign="center" color="mainColorText" marginTop={'30%'}>
                  <Heading
                    {...HEADING_STYLES}
                    paddingLeft="0px"
                    textAlign="center"
                    marginTop="16px"
                    fontSize={'24px'}
                  >
                    {txtForm.responseSubmitted[lang]}
                  </Heading>
                  <Button
                    {...RSVP_BUTTON_PROPS}
                    {...BTN_ALTERNATE}
                    marginTop="24px"
                    onClick={onResumbitRSVP}
                  >
                    {txtForm.changeInput[lang]}
                  </Button>
                </Box>
              )}
            </Box>
          </WithAnimation>
        </Box>
      </Box>
    </Box>
  );
}

RSVPSection.propTypes = {
  lang: string,
};

RSVPSection.defaultProps = {
  lang: 'id',
};

export default React.memo(RSVPSection);
