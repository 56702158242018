import React, { useState } from 'react';
import { arrayOf, object } from 'prop-types';
import { Box, Heading, Text } from '@chakra-ui/react';
import ReactSimplyCarousel from 'react-simply-carousel';
import { HiOutlineArrowNarrowLeft, HiOutlineArrowNarrowRight } from 'react-icons/hi';

function LoveStoryCard({ loveStoryData }) {
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);

  return (
    <Box position="relative">
      <ReactSimplyCarousel
        activeSlideIndex={activeSlideIndex}
        onRequestChange={setActiveSlideIndex}
        itemsToShow={1}
        itemsToScroll={1}
        forwardBtnProps={{
          //here you can also pass className, or any other button element attributes
          style: {
            position: 'absolute',
            left: '50%', // Center the button
            marginLeft: '10px', // Move it to the left by its width + half of the forward button's width
            bottom: '-50px',
            alignSelf: 'center',
            background: '#F9F7F4',
            color: '#1A1B1D',

            border: '1px solid black',
            cursor: 'pointer',

            height: 30,
            lineHeight: 1,
            textAlign: 'center',
            width: 70,
            padding: '5px 10px',
          },
          children: (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                height: '100%',
                fontSize: '24px',
              }}
            >
              <HiOutlineArrowNarrowRight />
            </div>
          ),
        }}
        backwardBtnProps={{
          //here you can also pass className, or any other button element attributes
          style: {
            position: 'absolute',
            left: '50%', // Center the button
            marginLeft: '-76px', // Move it to the left by its width + half of the forward button's width
            bottom: '-50px',
            alignSelf: 'center',
            background: '#F9F7F4',
            color: '#1A1B1D',

            border: '1px solid black',
            cursor: 'pointer',

            height: 30,
            lineHeight: 1,
            textAlign: 'center',
            width: 70,
            padding: '5px 10px',
          },
          children: (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                height: '100%',
                fontSize: '24px',
              }}
            >
              <HiOutlineArrowNarrowLeft />
            </div>
          ),
        }}
        speed={400}
        easing="linear"
      >
        {loveStoryData.map((item, index) => {
          return (
            <Box key={`index-${index}`} width={'300px'} maxWidth={'80vw'} textAlign={'center'}>
              <Heading fontSize="lg" marginBottom={'16px'}>
                {item.title}
              </Heading>
              <Text fontSize="lg" dangerouslySetInnerHTML={{ __html: item.description }}></Text>
            </Box>
          );
        })}
      </ReactSimplyCarousel>
    </Box>
  );
}

LoveStoryCard.propTypes = {
  loveStoryData: arrayOf(object),
};

LoveStoryCard.defaultProps = {
  loveStoryData: [],
};

export default LoveStoryCard;
